<template>
  <div>
    <el-dialog title="图片剪裁" :visible.sync="dialogVisiblex" :close-on-press-escape="false"
               :close-on-click-modal="false" append-to-body width="1000px">
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.outputSize"
                  :outputType="option.outputType"
                  :info="option.info"
                  :can-scale="option.canScale"
                  :auto-crop="option.autoCrop"
                  :auto-crop-width="option.autoCropWidth"
                  :auto-crop-height="option.autoCropHeight"
                  :fixed-box="option.fixedBox"
                  :fixed="option.fixed"
                  :fixed-number="option.fixedNumber"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  :full="option.full"
                  :enlarge="option.enlarge"
                  :mode="option.mode">
          </vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="rotate">旋转</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      dialogVisiblex: false,
      loading: false,
      option: {
        img: '', // 裁剪图片的地址 url 地址, base64, blob
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 160, // 默认生成截图框宽度
        autoCropHeight: 90, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain' // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      unimgurl: '', // 裁剪结果
      // success: () => {
      // } // 回调方法
    }
  },
  created() {

  },
  mounted() {
  },
  activated() {
  },
  methods: {
    showModal(obj, callback = ()=>{}) {
      this.croppingCompleteCallback = callback
      if (obj.img) {
        this.option.img = obj.img
      }
      //裁剪生成图片的质量
      if (obj.outputSize) {
        this.option.outputSize = obj.outputSize
      } else {
        this.option.outputSize = 1
      }
      //裁剪生成图片的格式
      if (obj.outputType) {
        this.option.outputType = obj.outputType
      } else {
        this.option.outputType = 'jpeg'
      }
      //裁剪框的大小信息
      if (obj.info) {
        this.option.info = obj.info
      } else {
        this.option.info = true
      }
      //图片是否允许滚轮缩放
      if (obj.canScale) {
        this.option.canScale = obj.canScale
      } else {
        this.option.canScale = true
      }
      //是否默认生成截图框
      if (obj.autoCrop) {
        this.option.autoCrop = obj.autoCrop
      } else {
        this.option.autoCrop = true
      }
      //截图框的宽高比例
      if (obj.fixedNumber) {
        this.option.fixedNumber = obj.fixedNumber
      } else {
        this.option.fixedNumber = [this.option.autoCropWidth, this.option.autoCropHeight]
      }
      const { autoCropWidth, autoCropHeight } = obj
      if (autoCropWidth && autoCropHeight) {
        this.option.autoCropWidth = autoCropWidth
        this.option.autoCropHeight = autoCropHeight
      } else {
        const benchmark = 200 - Number(this.option.fixedNumber[0])
        this.option.autoCropWidth = Number(this.option.fixedNumber[0]) + benchmark
        this.option.autoCropHeight = Number(this.option.fixedNumber[1]) + benchmark
      }
      //固定截图框大小 不允许改变
      if (obj.fixedBox) {
        this.option.fixedBox = obj.fixedBox
      } else {
        this.option.fixedBox = false
      }
      //是否开启截图框宽高固定比例
      if (obj.fixed) {
        this.option.fixed = obj.fixed
      } else {
        this.option.fixed = false
      }
      //上传图片是否可以移动
      if (obj.canMove) {
        this.option.canMove = obj.canMove
      } else {
        this.option.canMove = true
      }
      //截图框能否拖动
      if (obj.canMoveBox) {
        this.option.canMoveBox = obj.canMoveBox
      } else {
        this.option.canMoveBox = true
      }
      //上传图片按照原始比例渲染
      if (obj.original) {
        this.option.original = obj.original
      } else {
        this.option.original = false
      }
      //截图框是否被限制在图片里面
      if (obj.centerBox) {
        this.option.centerBox = obj.centerBox
      } else {
        this.option.centerBox = true
      }
      //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      if (obj.infoTrue) {
        this.option.infoTrue = obj.infoTrue
      } else {
        this.option.infoTrue = true
      }
      //是否输出原图比例的截图
      if (obj.full) {
        this.option.full = obj.full
      } else {
        this.option.full = true
      }
      //图片根据截图框输出比例倍数
      if (obj.enlarge) {
        this.option.enlarge = obj.enlarge
      } else {
        this.option.enlarge = '1'
      }
      //图片默认渲染方式
      if (obj.mode) {
        this.option.mode = obj.mode
      } else {
        this.option.mode = 'contain'
      }
      if (obj.success) {
        this.success = obj.success
      } else {
        this.success = () => {
        }
      }
      this.dialogVisiblex = true
    },
    rotate() {
      this.$refs.cropper.rotateRight()
    },
    finish() {
      // 获取截图的数据
      let that = this
      // this.$refs.cropper.getCropBlob(data => {
      //   that.unimgurl = data
      //   that.confirm()
      // })
      that.loading = true
      that.$refs.cropper.getCropData(data => {
        that.unimgurl = data
        that.$emit('finish', that.base64ToFile(that.unimgurl, (new Date().getTime())))
        this.croppingCompleteCallback && this.croppingCompleteCallback(that.unimgurl, (new Date().getTime()))
        that.loading = false
        that.dialogVisiblex = false
      })
    },
    confirm() {
      let that = this
      let res = this.base64ToFile(that.unimgurl, (new Date().getTime()))
      this.$emit('finish', res)
      this.dialogVisiblex = false
    },
    cancel() {
      this.dialogVisiblex = false
    },
    //将base64格式转换为文件格式，因为我这向后台提交数据时需要文件格式
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, {type: mime});
    },
  }
}
</script>
<style lang="scss" scoped>
.real_info_class {
  .el-checkbox__input .el-checkbox__inner {
    border-radius: 0;
  }
}

.file-image {
  width: 320px;
  height: 320px;
  font-size: 14px;
  border: 1px solid #cccccc;
  margin: 15px 0;
}

/* 截图 */
/* .cropper-content {} */
.cropper {
  width: 960px;
  height: 606px;
}
</style>