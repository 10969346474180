import Vue from 'vue'
import ImgCropper from '@/components/ImgCropper.vue'

const ToastConstructor = Vue.extend(ImgCropper)
const instance = new ToastConstructor().$mount()
document.body.appendChild(instance.$el)

/**
 * 唤起裁剪
 * @param config 裁剪配置
 * @param cb 裁剪回调
 */
export const arouseCropping = (config, cb) => {
    instance.showModal(config, cb)
}

/**
 * file转base64
 */
export const fileToDataURL = (blob, cb) => {
    let reader = new FileReader()
    reader.onload = function (evt) {
        let base64 = evt.target.result
        cb(base64)
    }
    reader.readAsDataURL(blob)
}

export const base64ToBlob = code => {
    const parts = code.split(';base64,')
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const rawLength = raw.length
    const uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: contentType })
}

/**
 * blob转file
 * @param blob
 * @returns {File}
 */
export const blobToFile = blob => {
    return new File([blob], 'file', {type: blob.type, lastModified: Date.now()});
}

/**
 * base64转File
 */
export const base64ToFile = params => blobToFile(base64ToBlob(params))
